* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    background: #212121;
    -webkit-overflow-scrolling: touch;
    color: #fff;
    font-family: Switzer, Helvetica, Arial, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, Noto Sans JP, Meiryo, メイリオ, sans-serif;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  html, body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  header {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      padding: 0px 60px;
      z-index: 2;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 0;
        transform: translate3d(0, -100%, 0);
        transition: transform .3s ease;
    }

    &.active {
        &::before, 
        .cta-h {
            transform: translate3d(0, 0%, 0);
        }
    }
  }

  .cta-h {
      display: flex;
      position: relative;
      column-gap: 10px;
      z-index: 1;
      margin: 0 -15px 0 0;
      transform: translate3d(0, -100%, 0);
      transition: transform .3s ease;
  }

  .cta-hbtn {
      position: relative;
      display: block;
      color: #fff;
      padding: 20px 15px;
      overflow: hidden;

      span {
            position: relative;
            z-index: 1;
        }

      @media (pointer: fine) {
        &::before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            transform: translate3d(0, 100%, 0);
            transition: transform .3s ease;
            z-index: 0;
        }
        span {
            transition: color .3s ease;
        }
        &:hover {
            &::before {
                transform: translate3d(0, 0, 0);
            }
            
            color: #212121;
        }
    }
  }

  .h-logo {
     font-size: 0;
      display: block;
      width: 100px;
      position: relative;
      z-index: 1;
      img {
          width: 100%;
        height: auto;
      }
  }

  p, h4, li, strong {
      margin: 0;
      user-select: text;
  }

  h1, h2, h3, .specText {
    font-family: PPEiko, Now, Helvetica, Arial, sans-serif;
    margin: 0;
    user-select: text;
    font-weight: 900;
  }


  h1 {
    text-transform: uppercase;
    font-size: 4vw;
    line-height: 4.2vw;
    font-weight: 900;
    letter-spacing: 2px;
  }

  h2 {
    font-weight: 900;
    font-size: 60px;
    line-height: 76px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    padding: 30px 0;
    // color: #242424;
  }

  h3 {
    font-size: 30px;
    line-height: 38px;
    padding: 0 0 6px;
  }

  a {
      text-decoration: none;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }
  ::selection { background: #FF5C5C; }

  section {
      max-width: 1024px;
      margin: 0 auto;
      padding: 60px 0;
  }

  .main {
    margin: 0;
    width: 55%;
    padding: 30px 60px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kv {
    position: absolute;
    left: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    &::before, &::after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        
    }
    &::before {
        width: 100%;
        height: 100%;
         background:url(
data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAABdJREFUGFdjZGBg+M/AwMDIAAVwBvECAEDqAQXmMAK9AAAAAElFTkSuQmCC
   ) repeat;
       z-index: 1;
       opacity: .02;
    }
    &::after {
        border-style: solid;
        border-width: 100vh 180px 0 0;
        border-color: #212121 transparent transparent transparent;
        z-index: 2;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
  }

  .mainc {
      position: relative;
      z-index: 1;
  }

  @keyframes scroll {
        0%   {transform: scaleX(0) translate3d(0%,-50%, 0);}
        50%  {transform: scaleX(1) translate3d(0, -50%, 0);}
        100% {transform: scaleX(1) translate3d(100%,-50%, 0);}
    }

  .scroll {
      position: absolute;
      left: 0;
      bottom: 0;
      span {
          overflow: hidden;
          font-size: 14px;
          position: relative;
          display: block;
          transform: rotate(90deg) translate(-50%, 150%);
          transform-origin: top center;
          padding: 0 40px 0 0;
          
          &::before {
              content: ' ';
              display: block;
              width: 40px;
              height: 1px;
              background: #fff;
              position: absolute;
              right: -10px;
              top: 50%;
              transform-origin: left center;
              animation: scroll .6s linear 2s infinite;
          }
      }
  }

.maindesc {
  max-width: 450px;
  padding: 25px 0 0;
  p {
      padding: 5px 0 0;
  }
}

 .casestudy {
    a {
        display: block;
        p {
            padding: 5px 0 0;
            color: #fff;
            font-size: 14px;
            text-transform: uppercase;
        }
        img {
            width: 100%;height: auto;
        }

        @media (pointer: fine) {
            img {
                transition: opacity .3s ease;
            }
            &:hover {
                img {
                    opacity: 0.8;
                }
            }
        }
    }
  }

.cta {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 25px 0 0;
}

.cta-btn {
    display: block;
    border: solid 1px #fff;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    padding: 10px 25px;
    position: relative;
    overflow: hidden;
    text-align: center;

    span {
        position: relative;
        z-index: 1;
    }

    @media (pointer: fine) {
        &::before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            transform: translate3d(0, 100%, 0);
            transition: transform .3s ease;
            z-index: 0;
        }
        span {
            transition: color .3s ease;
        }
        &:hover {
            &::before {
                transform: translate3d(0, 0, 0);
            }
            
            color: #212121;
        }
    }
}

.tech-adv {
    padding: 120px 0 60px;
}

.psmall {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tadv-list {
    video {
        max-width: 420px;;
    }
    li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 40px 0;
        div {
            width: 420px;
        }
        &.ar {
            justify-content: flex-end;
        }
    }
}

.mf-list {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    margin: 10px 0 48px;
    text-align: center;
    border-right: solid 1px #fff;
    border-bottom: solid 1px #fff;
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        padding: 30px 0;
        width: 25%;
        text-transform: uppercase;
        border-top: solid 1px #fff;
        border-left: solid 1px #fff;
    }
}

.m-features {
    h2 {
        text-align: center;
    }
}

footer {
    padding: 200px 60px 15px;
}

.tfooter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.bfooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 0;
    border-top: solid 1px #fff;;
}

.cpyright {
    font-size: 14px;
}

.tftitle {
    display: block;
    font-size: 12px;
    opacity: 0.8;
}

.address {
    .tftitle {
        display: none;
    }
     p {
    text-align: right;
    font-size: 15px;
    line-height: 20px;
    padding: 3px 0 30px;
    letter-spacing: 0.5px;
}
}

.contact {
    padding: 0 0 25px;
    a {
        display: inline-block;
        color: #fff;
        font-size: 32px;
        letter-spacing: 0.5px;
        position: relative;

        @media (pointer: fine) {
            &::before {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 1px;
                background: #FF5C5C;
                transform: scaleX(0) translateZ(0);
                transition: transform .3s ease;
                z-index: 0;
            }
            &:hover {
                &::before {
                    transform: scaleX(1) translateZ(0);
                }
            }
        }
    }
}

.lang {
    a {
        color: #fff;
        padding: 15px;
        font-size: 14px;
        display: block;
        @media (pointer: fine) {
            transition: opacity .3s ease;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}

.sns {
    display: flex;
    column-gap: 10px;
    margin: 0 -10px 0 0;
    a {
        padding: 15px 10px;
        font-size: 14px;
        display: block;
        color: #fff;

        @media (pointer: fine) {
            transition: opacity .3s ease;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}

  @media all and (max-width: 1220px) {
    .main {
        width: 65%;
        padding: 30px;
    }
    .kv {
        width: 40%;
        left: 60%;
        &::after {
           border-right: 120px solid #0000;
        }
    }
    h1 {
        font-size: 4.8vw;
        line-height: 5vw;
    }

    section {
        padding: 60px 30px;
    }

    .tech-adv {
        padding: 120px 30px 60px;
    }

    footer {
        padding: 200px 30px 15px;
    }
  }

@media all and (max-width: 979px) {
    .tadv-list video {
        max-width: 380px;
    }
    .tadv-list li div {
        width: 380px;
    }
}



@media all and (max-width: 860px) {

    .scroll {
      span {
          font-size: 12px;
          transform: rotate(90deg) translate(-50%, 220%);
          padding: 0 30px 0 0;
          
          &::before {
              width: 30px;
              right: -10px;
          }
      }
  }

    header {
        padding: 0 30px;
    }
    .main {
        padding: 30px;
    }

    h2 {
        font-size: 40px;
        line-height: 45px;
    }

    h3 {
        font-size: 24px;
        line-height: 32px;
    }

    .tadv-list video {
        max-width: none;
        width: 100%;
    }
    .tadv-list li {
        padding: 20px 0;
        div {
            width: 100%;
            max-width: 400px;
        }
    }

    .awb {
        div {
            padding: 40px 0 0;
            margin: 0 0 0 auto;
        }
    }

    .lsm {
        div {
            padding: 0 0 40px;
        }
    }

    .bfp {
        div {
            padding: 0 0 40px;
        }
        video {
            order: 1;
        }
    }

    .pfg {
        div {
            margin: 0 0 0 auto;
        }
    }
}


@media all and (max-width: 767px) {
    h1, .maindesc {
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    }
    h2 {
        font-size: 30px;
        line-height: 35px;
        padding: 20px 0;
    }

    h3 {
        font-size: 22px;
        line-height: 30px;
    }
    .mf-list li {
        width: 33.33%;
        font-size: 13px;
        padding: 15px 0;
        &:last-child {
            width: 100%;
        }
    }

    .mf-list {
        margin: 10px 0 30px;
    }

    .cpyright {
        font-size: 12px;
        width: 100%;
        order: 2;
        text-align: center;
        padding: 15px 0;
    }

    .bfooter {
        flex-wrap: wrap;
    }

    .contact a {
        font-size: 24px;
    }

    .main {
        width: 100%;
    }

    h1 {
        font-size: 6.2vw;
        line-height: 6.8vw;
    }

    .kv {
        width: 100%;
        left: 0;
        opacity: 0.15;
       &:before, &::after {
            display: none;
        }
    }

    .cta-hbtn {
        font-size: 14px;
    }

    .h-logo {
        width: 80px;
    }

    .cta-h {
        column-gap: 5px;
    }
}


@media all and (max-width: 600px) {
    h1 {
        font-size: 7.2vw;
        line-height: 7.8vw;
    }
}


@media all and (max-width: 460px) {

    .maindesc {
    padding: 15px 0 0;
    p {
        br {
            display: none;
        }
    }
    }

    .cta-hbtn {
        padding: 20px 10px;
    }
    footer {
        padding: 100px 30px 15px;
    }
    .contact {
        width: 100%;
    }
    .address {
        .tftitle {
            display: block;
        }
        p {
        text-align: left;
        br {
            display: none;
        }
        }
    }
    .tfooter {
        flex-wrap: wrap;
    }
    .bfooter {
        justify-content: center;
    }
    .sns {
        width: 100%;
        order: 0;
        justify-content: center;
    }
    .lang {
        padding: 10px 0;
        order: 1;
        a {
            padding: 10px 20px;
            border: solid 1px #ff5c5c;
        }
    }
}

@media all and (max-width: 370px) {

    .main {
        padding: 30px 15px;
    }

    header {
        padding: 0 15px;
    }

    .cta-btn {
        font-size: 14px;
        padding: 10px 20px;
    }

    .cta-h {
        a:last-child {
            display: none;
        }
    }

    h2 {
        font-size: 28px;
        line-height: 32px;
    }


    section {
        padding: 60px 15px;
    }

    .tech-adv {
        padding: 120px 15px 60px;
    }

    footer {
        padding: 100px 15px 15px;
    }

    .mf-list li {
        width: 50%;
        padding: 10px 0;
        &:last-child {
            width: 50%;
        }
    }

    .address p {
        width: 180px;
    }
}